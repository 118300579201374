@import "~@sartorius/ui/scss/_variables.scss";


@media screen and (max-width: 991.98px) {
  .row {
    display: block;
    min-height: 15.5rem;
    max-height: 15.5rem;
    align-content: center;
  }

  .row > .rightSideWrapper {
    margin-left: 10.4rem;
    width: calc(100% - 15rem);
  }
  
  .leftSideWrapper {
    width: 85%;
  }

  .rightSideWrapper {
    width: 30%;
  }
}

@media screen and (min-width: 991.98px) {
  .row {
    display: flex;
    min-height: 9.5rem;
  }
  .leftSideWrapper {
    width: 60%;
  }
  .rightSideWrapper {
    width: 35%;
  }
}

.accordion {
  margin-bottom: 2rem;

  .row {
    justify-content: space-between;
    margin-left: 0px;
    margin-right: 0px;
    background-color: $gray-200;
    max-height: fit-content;

    .icon {
      margin-bottom: 0;
      border: 2px solid $black;
    }
  }
}

.leftSideWrapper {
  position: relative;
  margin-left: 2rem;
  min-width: 35px;
  align-content: center;  
  overflow: hidden;
  display: flex;
  align-items: center;
}

.rightSideWrapper {
  display: flex;
  align-items: center;
  margin-right: 2rem;
  text-decoration: none;
}

.topText {
  font-size: 18px;
}

.bottomText {
  font-size: 16px;
}

.topText, .bottomText {
  margin-left: 2rem;
}
