@import '~@sartorius/ui/scss/variables';

.blackHeaderWrapper {
  margin-bottom: 2.5rem;
}

.sectionTitle {
  margin-bottom: 1rem;
}

.header {
  color: $gray-600;
  font-size: 1.4rem;
  margin-top: 0.7rem;
  min-height: 3rem;
}

.value {
  margin-top: 0.5rem;
  font-size: 1.6rem;
  padding-left: 1rem;
  min-height: 3rem;
  align-content: center;
  text-align: right;
}

.withGrayBackground {
  background-color: $gray-200;
}

@media screen and (max-width: 991.98px) {
  .desktopSection {
    display: none;
  }

  .withGrayBackground {
    padding-left: 1rem !important;
  }

  .value,
  .header,
  .sectionTitle {
    margin-left: 0px;
    padding-left: 0px;
  }

  .value {
    text-align: left;
  }
}

@media screen and (min-width: 991.98px) {
  .mobileSection {
    display: none;
  }


  .sectionTitle {
    margin-left: 0.4rem;
  }

  .header,
  .value {
    margin-left: 0.5rem;
  }
}

.lastRow {
  margin-bottom: 2rem;
}