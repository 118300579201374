@import '~@sartorius/ui/scss/variables';

.tooltip {
    display: inline-block;
    align-items: end;
}

.tooltip img {
    vertical-align: top;
}

.title {
    margin-top: 1rem;
    margin-bottom: 2.5rem;
}

.radioLabel {
    color: black;
}

.headerRow {
    margin-bottom: 1rem;
}

.secondRow {
    margin-bottom: 2.5rem;
}

.underHeaderText {
    font-size: 14px;
}

.headerWithTextUnder {
    margin-bottom: 0px;
}

.bottomColumn {
    margin-bottom: 4rem;
}

h4 {
    font-size: 2.3rem;
}

.section {
    background-color: #F5F5F5;
    padding-left: 2rem;
    padding-bottom: 2rem;
    padding-top: 1rem;
    margin-bottom: 2rem;
}

.headerWrapper {
    display: flex;
}

.icon {
    height: 64px;
    width: 64px;
    margin-right: 1.8rem;
}