@import "~@sartorius/ui/scss/_variables.scss";

.arrowContainer {
    text-align: center;
}

.nextArrow {
    background-color: $white;
    border:5px solid $white;
    filter: invert(1);
    height:4rem;
    border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;
    width:4rem;
    margin-top: 20rem;
}

.nextArrowHighlighted {
    background-color: $yellow;
    border:5px solid $yellow;
    height:4rem;
    border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;
    width:4rem;
    margin-top: 20rem;
}

.row {
    padding-left: 5rem;
}

.elementHeader {
    color: $gray-600;
    font-size: 14px;
}

.mainElement {
    margin-bottom: 2rem;
}

.topTitle {
    margin-bottom: 3rem;
    margin-top: 2rem;
}

.section {
    margin-bottom: 4rem;
}

.footerText {
    color: $gray-600;
    font-size: 14px;
}

.footerTextWrapper {
    margin-bottom: 7rem;
}