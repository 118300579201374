@import "~@sartorius/ui/scss/_variables.scss";

/* MultiSelectDropdown.css */
.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  border: 1px solid #dadada;
  border-radius: 0;
}

.top {
  bottom: 100%;
}

.dropdownButton {
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  width: 100%;
  height: calc(1.5em + 1.6rem + 2px);
  text-align: left;
  padding-left: 1.3rem;
  font-size: 1.4rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 4.5rem;
  color: $black;
  background-color: $white;
}

.dropdownButton:disabled {
  background-color: $gray-200;
}

.displayNone {
  display: none;
}

.displayBlock {
  display: block;
}

.dropdownContent {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  max-height: 24rem;
  overflow-y: auto; 
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  width: 100%;
}

.dropdownItem, .searchBar {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  text-align: left;
  width: 100%;
  height: 40px;
}

.searchBar {
  cursor: text;
  border: none;
  background-color: lighten($gray-100, 2);
  border-radius: 0;
  font-size: 13px;
}

.dropdownItem:hover {
  background-color: #f1f1f1;
}

.dropdownItem input {
  margin-right: 10px;
}

.checkbox {
  cursor: pointer;
  width: 1.8rem;
  height: 1.8rem;
  border: 1px solid #ccc;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  vertical-align: middle;
}

.checkbox:checked {
  background-color: $yellow;
  border-color: $yellow;
}

.checkbox:checked::before {
  background-image: url('../../assets/icons/check.svg');
  content: '';
  display: block;
  width: 1.8rem;
  height: 1.8rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.arrow {
  position: absolute;
  right: 30px;
  transition: transform 0.3s ease;
  width: 10px; 
  height: 10px;
}

.arrow.up {
  transform: translateY(100%) translateX(100%) rotate(180deg);
}

.icon {
  position: absolute;
  top: 50%;
  left: 10px; /* Adjust this value as needed */
  transform: translateY(-50%);
  pointer-events: none;
}

.searchBarWrapper {
  position: relative;
  display: inline-block;
  padding-left: 2.5rem;
  width: 100%;
}

.searchBar:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

.grayText {
  color: $gray-800;
}