@import '~@sartorius/ui/scss/variables';

.header {
  color: $gray-600;
  font-size: 1.4rem;
  margin-top: 2rem;
  margin-left: 0.5rem;
}

.value {
  margin-left: 0.5rem;
}

.firstRow {
  margin-top: 1rem;
}

.secondRow {
  margin-bottom: 3rem;
}