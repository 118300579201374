@import '~@sartorius/ui/scss/variables';

.modal {
  .modal-content {
    overflow: visible;
    max-height: 80vh !important;
  }

  .modal-body {
    font-weight: 200;
    font-size: 14px;
  }
}

// generic transform
@mixin transform() {
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

@media screen and (max-width: 991.98px) {
  .help-button {
    display: none;
  }
}

.help-button {
  height: 50px;
  width: 150px;
  top: auto !important;
  right: 0;
  position: fixed;
  text-align: center;
  transform-origin: bottom right;
  @include transform();
}

.help-text {
  margin-left: 10px;
}
