@import '~@sartorius/ui/scss/variables';

.arrowContainer {
    text-align: center;
}

.nextArrow {
    background-color: $white;
    border:5px solid $white;
    filter: invert(1);
    height:4rem;
    border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;
    width:4rem;
    margin-top: 20rem;
}

.nextArrowHighlighted {
    background-color: $yellow;
    border:5px solid $yellow;
    height:4rem;
    border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;
    width:4rem;
    margin-top: 20rem;
}

.row {
    padding-left: 5rem;
}

.singleSelect {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 3rem;
    background-position: calc(100% + 2rem) !important;
}

.dropdown {
    width: 100%;
    margin-bottom: 0px;
}

.disabledOption {
    color: gray;
}

.rightSideWrapper {
    display: flex;
    width: 100%;
}

.withTextbox {
    width: 30%;
    min-width: 8rem;
}

.textbox::placeholder {
    color: $gray-800;
}