// Icon Sets
@import '~@coreui/icons/css/free.css';
@import '~@sartorius/font/css/sartorius-icon-font.css';

@import '~@sartorius/ui/scss/variables';
$card-spacer-x: 2rem;
$tooltip-max-width: 300px;

// Styles
@import '~@sartorius/ui/scss/styles';

// overwrites

label {
  color: #838383;
}

.c-header {
  .c-header-nav-link button {
    margin: 2rem 0 !important;

    img {
      height: 25px !important;
      width: 25px !important;
    }

    &:hover {
      img {
        filter: invert(1);
      }
    }
  }
}

.card {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;

  .card-header {
    background-color: inherit;
    color: inherit;
    font-size: 1.70rem;
    padding-left: 1rem;
    font-weight: bold;
  }

  .card-body {
    font-size: 1.4rem;
    //color: $gray-800;
    padding-top: 1.55rem;
    padding-bottom: 3.25rem;
  }

  .card-footer {
    background-color: inherit;
    color: inherit;
    font-size: 1.6rem;
  }
}

.modal {
  .modal-header {
    font-weight: bold;

    button.close {
      margin: -3rem -3rem;
      padding: 0 0.75rem;
      font-weight: 100;
      font-size: 4rem;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
}
// styles are overriden in order to fix a bug where after closing a modal the modal button loses its styles until it is interracted wit
.btn-secondary {
  color: $white !important;
  background-color: $black !important;

  &:hover {
    color: $black !important;
    background-color: $yellow !important;
  }
}

.btn-secondary-active {
  color: $black !important;
  background-color: $yellow !important;
}

.img-muted {
  opacity: 0.4;
}

.was-validated {
  .form-control {
    &:not(select) {
      background-image: none !important;
    }
    &:invalid {
      border-color: red !important;
    }
  }
  select.form-control {
    background-image: $select-dropdown-background;
    background-position: bottom right !important;
    padding-right: $input-padding-x !important;
    padding-left: $input-padding-x !important;
  }
}

.btn.btn-primary:hover {
  border-color: black !important;
}

.software-selection-checkbox.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-color: white;
  border: solid 1px $gray-500;
}

.custom-control-input {
  &:checked, &:not(:disabled):active {
    &~ .custom-control-label::before {
      background-color: $black;
      border-color: $black;
    }
  }
}

@include media-breakpoint-down(md) {
  .c-wrapper .c-header-fixed {
    position: initial !important;
  }
}

.muted {
  background-color: lighten($gray-100, 2);
}


@media screen and (max-width: 991.98px) {
  .header-wrapper {
    display: none;
  }

  .mobileHeader-wrapper {
    position: sticky;
    top: 0;
    z-index: 1000;
    width: 100%;
  }
  
  .c-body {
    padding: 20px;
  }
  .c-main {
    padding-top: 0px;
  }
}

@media screen and (min-width: 991.98px) {
  .mobileHeader-wrapper {
    display: none;
  }
}
