@import '~@sartorius/ui/scss/variables';

.section {
  background-color: $gray-200;
  width: 100%;
}

.textCol {
  margin-top: 1.5rem;
  font-size: 16px;
}

.textValue {
  font-size: 15px;
}

.whiteTextCol {
  background-color: white;
  padding-top: 1.5rem;
  font-size: 16px;
}

.whiteValueCol {
  background-color: white;
  text-align: right;
}

.valueCol {
  text-align: right;
}

.text {
  margin-left: 2rem;
}

.value {
  margin-right: 1rem;
  width: 26px;
  height: 26px;
  float: right;
  margin-top: 1rem;
  font-size: 24px;
}

.dash {
  margin-right: 1rem;
}

.lastRow {
  margin-bottom: 2rem;
}

.nested {
  margin-left: 4.5rem;
}

.nestedWhite {
  padding-left: 5rem;
}

.lastWhiteRow {
  margin-bottom: 2rem;
}

@media screen and (max-width: 991.98px) {
  .textCol {
    font-size: 15px;
  }
}