.headline {
  font-family: "TTNormsPro-Medium";
  font-size: 1.4rem;
  color: #fff;
  line-height: 2.4rem;
}

.copyright {
  margin-top: 30px;
}

.list {
  padding: 0;
  li {
    list-style: none;
    padding: 0;
    font-size:1.4rem;
    line-height:2.4rem;
    a {
      color:#fff;
      text-decoration:none;
      padding:0;
      &:focus,
      &:hover {
        color:#000;
        background-color: rgb(255, 237, 0);
      }
    }
  }
}

.bottomNavigationButtonContainer{
  margin-bottom: 5rem;
  float: right;

    button:hover {
      img {
        filter: invert(1);
      }
    }
  
    button {
      &.disabled,
      &:disabled {
        opacity: 1 !important;
        color: #838383 !important;
        outline-color: #d2d2d2 !important;
  
        &:hover {
          cursor: not-allowed;
        }
  
        img {
          filter: invert(0);
        }
      }
    }
}