@import '~@sartorius/ui/scss/variables';

.header {
  color: $gray-600;
  font-size: 1.4rem;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}

.value {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.firstRow {
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}

.desktopSecondSubsection {
  margin-bottom: 3rem;
}

@media screen and (max-width: 991.98px) {
  .desktopSecondSubsection {
    display: none;
  }
}

@media screen and (min-width: 991.98px) {
  .mobileSecondSubsection {
    display: none;
  }
}