@import '~@sartorius/ui/scss/variables';

.blackRow {
  background-color: black;
  color: white;
  min-height: 6.5rem;
  font-size: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.withoutCursor {
  cursor: default !important;
}

.blackRow:hover {
  color: white;
}

.headerText {
  margin-left: 1rem;
}

.pencil {
  filter: invert(1);
  float: right;
  width: 1.7rem;
  height: 1.7rem;
  margin-right: 1rem;
}